var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"วิทยาลัยขอรับย้าย ข้าราชการครูและบุคลากรทางการศึกษา"}},[_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","filled":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.OnEnter()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.colleges,"item-text":"college_name","item-value":"college_code","label":"สถานศึกษา"},on:{"change":function($event){return _vm.search_college()}},model:{value:(_vm.college_search),callback:function ($$v) {_vm.college_search=$$v},expression:"college_search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"block":""}},[_vm._v("อนุมัติย้ายข้อมูลทั้งหมด")]),_c('v-btn',{attrs:{"block":"","color":"info"},on:{"click":function($event){return _vm.collegepersonnelreqQueryAll()}}},[_vm._v("แสดงข้อมูลทั้งหมด")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.collegepersonnelreqs,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.NewCollegeReq",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('h3',[_vm._v(_vm._s(item.NewCollegeReq))])])]}},{key:"item.OldCollege",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"warning","dark":""}},[_c('h3',[_vm._v(_vm._s(item.OldCollege))])])]}},{key:"item.brith_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.brith_day + "/" + item.brith_month + "/" + item.brith_year)+" ")]}},{key:"item.appoin_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year)+" ")]}},{key:"item.collegePersonnelApp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.collegePersonnelApp,"add", "543 years"),"D MMMM YYYY"))+" ")]}},{key:"item.collegePersonnelAppOrderDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.collegePersonnelAppOrderDate,"add", "543 years"),"D MMMM YYYY"))+" ")]}},{key:"item.actionApp",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.collegepersonnelreqApprove(item.personalIDcardReq)}}},[_c('v-icon',[_vm._v(" mdi-account-convert ")]),_vm._v(" อนุมัติข้อมูล")],1)]}},{key:"item.actionCancel",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.collegepersonnelreqCancel(item.personalIDcardReq)}}},[_c('v-icon',[_vm._v(" mdi-close-circle-outline ")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }