<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="วิทยาลัยขอรับย้าย ข้าราชการครูและบุคลากรทางการศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="9" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                v-on:keyup.enter="OnEnter()"
                filled
                class="mb-2"
              />
              <v-autocomplete
                v-model="college_search"
                outlined
                :items="colleges"
                item-text="college_name"
                item-value="college_code"
                label="สถานศึกษา"
                @change="search_college()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn block class="mb-2">อนุมัติย้ายข้อมูลทั้งหมด</v-btn>
              <v-btn block color="info" @click="collegepersonnelreqQueryAll()">แสดงข้อมูลทั้งหมด</v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="collegepersonnelreqs"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.NewCollegeReq="{ item }">
            <v-chip color="green" dark>
              <h3>{{ item.NewCollegeReq }}</h3>
            </v-chip>
          </template>

          <template v-slot:item.OldCollege="{ item }">
            <v-chip color="warning" dark>
              <h3>{{ item.OldCollege }}</h3>
            </v-chip>
          </template>

          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
          </template>
          <template v-slot:item.appoin_day="{ item }">
            {{
              item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
            }}
          </template>

          <template v-slot:item.collegePersonnelApp="{ item }">
            {{
              item.collegePersonnelApp
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.collegePersonnelAppOrderDate="{ item }">
            {{
              item.collegePersonnelAppOrderDate
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.actionApp="{ item }">
            <v-btn color="warning" @click="collegepersonnelreqApprove(item.personalIDcardReq)">
              <v-icon
                
              >
                mdi-account-convert
              </v-icon>
              อนุมัติข้อมูล</v-btn
            >
          </template>

          <template v-slot:item.actionCancel="{ item }">
            <v-btn fab small color="red" @click="collegepersonnelreqCancel(item.personalIDcardReq)">
              <v-icon
                
              >
                mdi-close-circle-outline
              </v-icon></v-btn
            >
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      search: "",
      pagination: {},
      headers: [
        {
          text: "สังกัดใหม่",
          align: "center",
          value: "NewCollegeReq"
        },
        {
          text: "สังกัดเดิม",
          align: "center",
          value: "OldCollege"
        },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "personalIDcardReq"
        },
        { text: "ชื่อนามสกุล", align: "center", value: "frist_names" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่งเดิม", align: "center", value: "id_position" },
        { text: "เลขที่ตำแหน่งใหม่", align: "center", value: "collegeNewIdPosition" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
        {
          text: "คำสั่งที่",
          align: "center",
          value: "collegePersonnelAppOrder"
        },
        {
          text: "วันที่ปฏิบัติหน้าที่",
          align: "center",
          value: "collegePersonnelApp"
        },
        {
          text: "วันที่คำสั่ง",
          align: "center",
          value: "collegePersonnelAppOrderDate"
        },
        {
          text: "อนุมัติ",
          align: "center",
          value: "actionApp",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ยกเลิก",
          align: "center",
          value: "actionCancel",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      collegepersonnelreqs: [],
      collegepersonnelreqQr:[],
      collegepersonnelreqApp:{},
      collegepersonnelreqDel:{},
      colleges:[],
      college_search:"",
      jor18sMove:{},
      jor18sClear:{},
    };
  },

  async mounted() {
    await this.sweetAlertLoading();
    await this.collegepersonnelreqQueryAll();
    await this.collegesQuery();

    Swal.close();
  },

  methods: {
    async collegepersonnelreqCancel(personalIDcardReq){
      let result = await this.$http.post("collegepersonnelreq.php", {
        ApiKey: this.ApiKey,
        personalIDcardReq: personalIDcardReq
      });
      this.collegepersonnelreqQr = result.data;         
      let personnelApp = this.collegepersonnelreqQr.frist_names;
      Swal.fire({
        title: "ไม่อนุมัติย้ายข้อมูลบุคคล",
        text: personnelApp,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {  

          this.collegepersonnelreqDel.ApiKey = this.ApiKey;
          this.collegepersonnelreqDel.personalIDcardReq = this.collegepersonnelreqQr.id_card;

          let resultDelReq = await this.$http.post(
            "collegepersonnelreq.delete.php",
            this.collegepersonnelreqDel
          );   
    
             
          if (resultDelReq.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });      
    await this.collegepersonnelreqQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }         
        }
      });
    },

    async collegepersonnelreqApprove(personalIDcardReq){
        let result = await this.$http.post("collegepersonnelreq.php", {
        ApiKey: this.ApiKey,
        personalIDcardReq: personalIDcardReq
      });
      this.collegepersonnelreqQr = result.data;     
      
      let personnelApp = this.collegepersonnelreqQr.frist_names;
      Swal.fire({
        title: "อนุมัติย้ายข้อมูลบุคคล",
        text: personnelApp,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {  

          this.collegepersonnelreqApp.ApiKey = this.ApiKey;
          this.collegepersonnelreqApp.id_card=this.collegepersonnelreqQr.id_card;
          this.collegepersonnelreqApp.college_code=this.collegepersonnelreqQr.collegeNewReq;
          this.collegepersonnelreqApp.id_position=this.collegepersonnelreqQr.collegeNewIdPosition;
          this.collegepersonnelreqApp.order_app_now=this.collegepersonnelreqQr.collegePersonnelAppOrder;
          this.collegepersonnelreqApp.date_app_now=this.collegepersonnelreqQr.collegePersonnelApp;   

          this.collegepersonnelreqDel.ApiKey = this.ApiKey;
          this.collegepersonnelreqDel.personalIDcardReq = this.collegepersonnelreqQr.id_card;

          this.jor18sMove.ApiKey = this.ApiKey;
          this.jor18sMove.id_position = this.collegepersonnelreqQr.collegeNewIdPosition;
          this.jor18sMove.j18college_code = this.collegepersonnelreqQr.collegeNewReq;
          this.jor18sMove.id_card = this.collegepersonnelreqQr.id_card;
          this.jor18sMove.titles = this.collegepersonnelreqQr.title_s;
          this.jor18sMove.first_name = this.collegepersonnelreqQr.frist_name;
          this.jor18sMove.last_name = this.collegepersonnelreqQr.last_name;
          this.jor18sMove.rang_positon = this.collegepersonnelreqQr.rang_name;

          this.jor18sClear.ApiKey = this.ApiKey;
          this.jor18sClear.id_position = this.collegepersonnelreqQr.id_position;
          this.jor18sClear.j18college_code = this.collegepersonnelreqQr.college_code;

          
let resultClearJ18 = await this.$http.post(
            "jor18.updateClear.php",
            this.jor18sClear
          );      
        
          
          let resultUpdateJ18 = await this.$http.post(
            "jor18.updateMove.php",
            this.jor18sMove
          );      
         
          let resultUpdatePerTem = await this.$http.post(
            "personnel_temporary.updateMove.php",
            this.collegepersonnelreqApp
          );         
        

          let resultDelReq = await this.$http.post(
            "collegepersonnelreq.delete.php",
            this.collegepersonnelreqDel
          );   
    
            
          
          
          
          
         
       

          if (resultUpdatePerTem.data.status == true && resultDelReq.data.status == true && resultUpdateJ18.data.status == true && resultClearJ18.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });      
    await this.collegepersonnelreqQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }         
        }
      });
    },


    async collegesQuery(){
         let result = await this.$http
        .post("college.php", {
          ApiKey: this.ApiKey
        })
      this.colleges = result.data;
    },
   

    async search_college() {
      this.loading = true;
      let result = await this.$http
        .post("collegepersonnelreq.php", {
          ApiKey: this.ApiKey,
          collegeNewReq: this.college_search
        })
        .finally(() => (this.loading = false));
      this.collegepersonnelreqs = result.data;
    },


    async collegepersonnelreqQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("collegepersonnelreq.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.collegepersonnelreqs = result.data;
      this.collegesQuery()
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();    
        },
        onBeforeOpen: () => {
          Swal.showLoading();       
        },
        onAfterClose: () => {
          Swal.hideLoading();      
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
